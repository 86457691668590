import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style.css"
import { useLocation } from '@reach/router';

export const CWScripts = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
    function addContent() {
      var orderLink = document.querySelector('#order-now-link').getAttribute('href');
      var stickyBarHTML = `
        <div class="cw_sw_1_sticky_bar">
          <div class="cw_sw_1_sticky_bar_container">
            <div class="cw_sw_1_flex_container">
              <div class="cw_sw_1_flex_content">
                <div class="cw_sw_1_mn_heading">Get SketchWow for a 1-Time Price</div>
                <div class="cw_sw_1_mn_content">ZERO MONTHLY / ANNUAL FEES FOREVER</div>
              </div>
              <div class="cw_sw_1_mn_cta">
                <a href="${orderLink}">Order Now</a>
              </div>
            </div>
          </div>
        </div>
      `;
      document.querySelector('main').insertAdjacentHTML('afterend', stickyBarHTML);
    }
  
    var observer = new MutationObserver(function(mutations, me) {
      if (document.querySelector('main')) {
       setTimeout(addContent, 1000);
        me.disconnect();
      }
    });
  
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  
    window.addEventListener('scroll', function() {
      var stickyBar = document.querySelector('.cw_sw_1_sticky_bar');
      if (stickyBar) {
        if (window.scrollY >= 1000) {
          document.body.classList.add('scroll_active');
          stickyBar.classList.add('sticky_active');
        } else {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        }

        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        } 
      }
    });
}, []);

 

  return (
    <>  </>
            
  );

  
};
